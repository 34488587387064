<script>
export default {
  data() {
    return {
      isLoading: true,
      loadingTitle: this.$t('地址跳转中...'),
      url:'https://m.dtmszms.org/alive/4/'
    }
  },
  created() {
    const { query } = this.$route  
    console.log(query)
    this.onLoad();
  },
  methods: {
    onLoad(){
      this.isLoading = false
      //this.goTo()
    },
    toLive(){
      let timestamp = Math.floor(Date.parse(new Date()) / 1000);
      window.location.href =  `https://m.dtmszms.org/alive/4/${timestamp}`;
    },
    toCiguang(){
      let timestamp = Math.floor(Date.parse(new Date()) / 1000);
      window.location.href =  `https://m.dtmszms.org/cg/${timestamp}`;
    }
  },
};
</script>
<template>
  <div>
    <div class="content" justify="center">
      <van-space direction="vertical" fill >
        <van-image
            style="margin-bottom: 20px;" 
            lazy-load
            src="https://7n.ciguang.tv/uploads/image/appinfo/20230725/1c5f6fcd1a0e9974e6de25923ae09490.jpg"
          />
        
        <van-button type="warning" block style="margin-bottom: 20px;" @click="toLive()">收看直播</van-button>
        <van-button type="primary" block style="margin-bottom: 20px;" @click="toCiguang()">下载慈光讲堂APP收看</van-button>
      </van-space>
      <Loading v-bind:title="loadingTitle" v-bind:show="isLoading"/>
    </div>
  </div>
</template>
<style lang="less">
  .content{
    margin: 10px 10px 10px 10px;
  }
</style>
